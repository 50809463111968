import React from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import Button from "../../../atoms/Button";
import { ButtonsGroup } from "./SliderButtons";
import { useCurrentSlide } from "./index";
import { Text, Title } from "./Components";

const Container = styled.div`
  z-index: 990;
  width: 100%;
  max-width: 1560px;
  margin-left: -150px;
  height: 100%;
  position: relative;

  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: -20px;
  }
  .react-multiple-carousel__arrow {
    display: none;
  }
`;
const SliderCardWrapper = styled.div`
  width: 460px;
  user-select: none;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
  transition: all 0.2s linear;
  transform: ${(props) =>
    props.size === "small"
      ? "scale(0.7)"
      : props.size === "smaller"
      ? "scale(.4)"
      : "scale(1)"};
  opacity: ${(props) =>
    props.size === "small" ? "0.5" : props.size === "smaller" ? ".2" : "1"};
  img {
    user-select: none;
  }
`;

const StyledTitle = styled(Title)`
  letter-spacing: 1px;
  font-size: 32px;
`;
const StyledText = styled(Text)`
  font-size: 25px;
`;
const ButtonWrapper = styled.div`
  position: relative;
  padding-top: 100px;
  margin-left: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 200;
`;

const OfferCarousel = ({ preview }) => {
  const carouselRef = React.useRef();
  const [bg, setBg] = React.useState();
  const [previewLink, setPreviewLink] = React.useState(preview[0].href);
  const [currentSlide, setCurrentSlide] = useCurrentSlide();
  React.useEffect(() => {
    // console.log(carouselRef.current);
    // carouselRef.current.state.currentSlide = currentSlide;
    // console.log(preview[carouselRef?.current?.state?.currentSlide]);
    // setPreviewLink(preview[carouselRef?.current?.state?.currentSlide - 1].href);
    setBg(
      <ButtonsGroup
        max={preview.length - 5}
        currentSlide={currentSlide}
        next={carouselRef?.current?.next}
        previous={carouselRef?.current?.previous}
      />
    );
  }, [carouselRef?.current?.state]);
  React.useEffect(() => carouselRef.current?.goToSlide(2), []);
  const className = "reccly-dev--portfolio-slider";

  return (
    <Container>
      <Carousel
        afterChange={(nextSlide, { currentSlide, onMove }) =>
          setCurrentSlide(currentSlide)
        }
        additionalTransfrom={0}
        autoPlaySpeed={3000}
        centerMode={false}
        className={className}
        containerClass={className + "--container"}
        dotListClass={className + "--dotlist"}
        itemClass={className + "--item"}
        draggable
        ref={carouselRef}
        focusOnSelect={false}
        keyBoardControl
        minimumTouchDrag={80}
        responsive={{
          always: {
            breakpoint: {
              max: 10000,
              min: 0
            },
            items: 5
          }
        }}
        ssr
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {preview.map((p, i) => (
          <SliderCard i={i} p={p} />
        ))}
      </Carousel>
      <ButtonWrapper>
        <Button data_color="blue">
          Rozpocznijmy magiczny projekt {preview[currentSlide + 2].type ?? ""}!
        </Button>
      </ButtonWrapper>
      {bg}
    </Container>
  );
};
const SliderCard = ({ i, p }) => {
  const [currentSlide] = useCurrentSlide();
  const [size, setSize] = React.useState("smaller");
  React.useEffect(() => {
    const slideSize = (index) => {
      var i = index - 2;
      if (i === currentSlide - 1 || i === currentSlide + 1) {
        return "small";
      }
      if (i <= currentSlide - 2 || i >= currentSlide + 2) {
        return "smaller";
      }
      return "normal";
    };
    return setSize(slideSize(i));
  }, [currentSlide]);

  return (
    <SliderCardWrapper size={size}>
      <img
        src={p.src}
        key={p.src + "_" + i}
        alt={p.src}
        style={{
          display: "block",
          margin: "auto"
        }}
      />
      <StyledTitle color="white">{p.title}</StyledTitle>
      <StyledText color="white">{p.description}</StyledText>
    </SliderCardWrapper>
  );
};
export default OfferCarousel;
