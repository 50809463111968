import React from "react";
import styled, { keyframes } from "styled-components";
import Typewriter from "typewriter-effect";

const rotateLeft = keyframes`
from{
    transform: rotate(0deg);
}
to{
    transform: rotate(360deg);
}
`;
const rotateRight = keyframes`
from{
    transform: rotateZ(0deg);
}
to{
    transform: rotateZ(-359deg);
}
`;

const Container = styled.div`
  position: relative;
  width: 650px;
  margin-top: 90px;
  height: 650px;
`;
const RecclyLogo = styled.div`
  background-image: url(/banner/image/reccly_logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 260px;
  height: 62px;
  z-index: 2;
  position: absolute;
  top: 160px;
  left: 180px;
`
const FirstWheel = styled.div`
  z-index: 0;
  position: absolute;
  top: -90px;
  animation: ${rotateRight} 6s linear infinite;
  width: 185px;
  left: 40%;
  height: 185px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/banner/image/circle_1.svg);
`;
const SecondWheel = styled.div`
  top: -55px;
  z-index: 0;
  animation: ${rotateLeft} 4s linear infinite;
  left: 25%;
  position: absolute;
  width: 125px;
  height: 125px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/banner/image/circle_1.svg);
`;
const Laptop = styled.div`
  top: 25px;
  z-index: 1;
  position: absolute;
  width: 600px;
  height: 380px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/banner/image/laptop.svg);
`;
const Guy = styled.div`
  bottom: 110px;
  right: 0;
  z-index: 4;
  position: absolute;
  width: 290px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/banner/image/ludek.svg);
`;
const Shield = styled.div`
  z-index: 2;
  position: absolute;
  right: 0;
  width: 320px;
  height: 480px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/banner/image/tarcza.svg);
`;

const TypewriterWrapper = styled.div`
  user-select: none;
  width: 135px;
  height: 205px;
  right: 30px;
  color: #27ae60;
  top: 50px;
  position: absolute;
  font-size: 12px;

  padding: 13px;
  padding-left: 8px;
  font-family: CascadiaCodePL;
  letter-spacing: -0.3px;
  z-index: 3;
`;

const BannerImage = () => {
  return (
    <Container>
      <FirstWheel />
      <SecondWheel />
      <RecclyLogo />
      <Laptop />
      <Guy />

      <TypewriterWrapper>
        <Typewriter
          className="reccly-typewriter"
          options={{
            loop: true
          }}
          onInit={(typewriter) => {
            typewriter
              .pauseFor(2000)
              .typeString(
                `<span style="color: #0B151E;">const</span> <span style="color: #090808;">[</span><span style="color: #46BFF6;">isSitePrepared</span><span style="color: #27ae60;">,</span> <span style="color: #46BFF6;">setSitePrepared</span> <span style="color: #090808;">]</span> <span style="color: #27ae60;">=</span> <span style="color: #49B4EF;">React</span><span style="color: #27ae60;">.</span>useState</span><span style="color: #090808;">(</span><span style="color: #090808;">)</span><span style="color: #27ae60;">;</span>
                useEffec`
              )
              .pauseFor(500)
              .deleteChars(8)
              .pauseFor(200)
              .typeString(
                `<span style="color: #49B4EF;">React</span><span style="color: #27ae60;">.</span>useMemo<span style="color: #090808;">(()</span> => <span style="color: #090808;">{</span><span style="color: #46BFF6;">isSitePrepared</span> && sendLovesTo<span style="color: #090808;">( <span color="#CE9178">"Reccly"</span> )</span><span style="color: #090808;">}</span><span style="color: #090808;">)</span><span style="color: #27ae60;">;</span>`
              )
              .pauseFor(20000)
              .start();
          }}
        />
      </TypewriterWrapper>
      <Shield />
    </Container>
  );
};

export default BannerImage;
