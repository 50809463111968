import React from "react";
import styled from "styled-components";
const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 200px;
  bottom: -50px;

  background: url(/topShape.svg);
  background-size: cover;
`;

const TopShape = () => {
  return <Container />;
};

export default TopShape;
