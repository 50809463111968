import React, {useState, useEffect} from "react";
import styled from "styled-components";
import Title from "../../../atoms/Title";
import Text from "../../../atoms/Text";
import Button, { ButtonOutline } from "../../../atoms/Button";
import TopLeftSplash from "./TopLeftSplash";
import BannerImage from "../../../atoms/images/BannerImage";
import useMightyMouse from "react-hook-mighty-mouse";
import { useStaticQuery, graphql } from "gatsby";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;
const Linie = styled.div`
  width: 1191px;
  height: 638px;

  background-image: url(/banner/linie.svg);
  background-size: contain;

  position: absolute;
  z-index: 2;
  top: 13vw;
  right: 5vw;
`;
const Drongal = styled.div`
  /* width: 100vw; */
  /* position: absolute; */
  background: red;
  /* height: 100px; */
  z-index: 2;
  &:after {
    content: "";
    background-image: url(/banner/yellow-drongal.svg);
    background-size: contain;
    width: 1481px;
    height: 1660px;
    position: absolute;
    z-index: 1;
    left: 12vw;
    top: -55%;
  }
`;
const SmallShape = styled.div`
  width: 40vw;
  position: absolute;
  top: 22vw;
  right: -19vw;
  z-index: 1;
  height: 40vw;
  background-image: url(/banner/shape2_red.svg);
  background-repeat: no-repeat;
  background-size: contain;
  &:after {
    content: "";
    position: absolute;
    top: 10%;
    left: 10%;
    width: 50%;
    height: 50%;
    background-image: url(/banner/shape_red.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Dot = styled.div`
  width: 8vw;
  position: absolute;
  height: 8vw;
  /* top: 0; */
  /* left: 0; */
  z-index: 1;
  background-image: url(/banner/circle_yeallow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  /* background: red; */
  right: 21.8vw;
  top: 42vw;
`;
const BigShape = styled.div`
  clip-path: url(#my-clip-path);
  position: absolute;
  top: -150px;
  left: -10px;
  z-index: 1;
  width: 100.84vw;
  height: 69.115625vw;
  /* width: 100vw;
  height: 60vw; */
`;
const Gradient = styled.div`
  width: 100%;
  clip-path: url(#my-clip-path);
  height: 100%;
  background: radial-gradient(
    circle at 50% 120%,
    #00123c,
    #00123b,
    #00113a,
    #011037,
    #010f34,
    #020d31,
    #020b2d,
    #020a2a,
    #010827,
    #010624,
    #000523,
    #000522
  );
`;

const Container = styled.div`
  display: flex;
  padding-top: 100px;
  width: 100%;
  margin: 0 auto;
  max-width: 1560px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 0;
    top: 0;
    background: #fff;
  }
  svg {
    /* display: none; */
    position: absolute;
    opacity: 0;
    transform: translateX(-1000vw);
  }
`;
const TextWrapper = styled.div`
  width: 50%;
  /* background: blue; */
  margin-top: -150px;
  position: relative;
  z-index: 1;
  padding-left: 3.2vw;
`;
const TextWrapperContent = styled.div`
  width: 100%;
`;
const StyledTitle = styled(Title)`
  margin-bottom: 25px;
`;
const StyledText = styled(Text)`
  max-width: 570px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  button {
    margin-right: 40px;
  }
`;
const ImageWrapper = styled.div`
  width: 50%;
  /* transform: scale(0.9); */
  margin-top: -40px;
  padding-left: 55px;
  /* background: red; */
  z-index: 1;
  /* height: 1000px; */
  /* padding-left: 15vw; */
`;

const Header = () => {
  // const lang =
  //   typeof window !== "undefined"
  //     ? window.location.search !== ""
  //       ? window.location.search.split("?")[1]
  //       : "pl"
  //     : "pl";

      const data = 
      useStaticQuery( graphql`
      {
        allParagraphTitleDescriptionButtonButton2{
          nodes {
            field_title {
              value
            }
            field_description {
              value
            }
            field_button {
              uri
              title
            }
            field_button_2 {
              uri
              title
            }
            langcode
            drupal_id
          }
        }
      }
    `
    )
    const [lang, setLang] = useState(  "pl")
    const [headerContent, setHeaderContent] = useState(lang === "pl" ? data.allParagraphTitleDescriptionButtonButton2.nodes[0] : data.allParagraphTitleDescriptionButtonButton2.nodes[1])
    useEffect(()=>{
setLang( 
  typeof window !== "undefined"
    ? window.location.search !== ""
      ? window.location.search.split("?")[1]
      : "pl"
    : "pl"
)
    },[])
    useEffect(()=>{
      setHeaderContent(lang === "pl" ? data.allParagraphTitleDescriptionButtonButton2.nodes[0] : data.allParagraphTitleDescriptionButtonButton2.nodes[1])
    },[lang])
  const { position } = useMightyMouse();
  return (
    <Background>
      {console.log(data)}
      {console.log(lang)}

      <Container>
        <BigShape className="clipped">
          <Drongal />
          <Linie />
          <Gradient />
        </BigShape>
        <SmallShape />
        {/* <DotWrapper> */}
        {/* <ParallaxWrapper power={250}> */}
        <Dot
          style={{
            transform: `translateX(${
              (position.client.x / 250) * 2
            }px) translateY(${(position.client.y / 150) * 1.5}px)`,
            transition: "all 0.2 ease-in-out"
          }}
        />
        {/* </ParallaxWrapper> */}
        {/* </DotWrapper> */}
        <TextWrapper>
          <TextWrapperContent>
            {console.log(headerContent)}
            <StyledTitle color="white">{headerContent.field_title.value}</StyledTitle>
            <StyledText color="white">
            {headerContent.field_description.value}
            </StyledText>
          </TextWrapperContent>
          <ButtonsWrapper>
            <Button data_color="blue">{headerContent.field_button.title}</Button>
            <ButtonOutline data_color="blue">
              <span>{headerContent.field_button_2.title}</span>
            </ButtonOutline>
          </ButtonsWrapper>
        </TextWrapper>
        <ImageWrapper>
          <BannerImage />
        </ImageWrapper>

        <svg className="svg">
          <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
            <path d="M0,1 a0.343,0.491,0,0,1,0.042,-0.072 C0.1,0.847,0.184,0.793,0.326,0.765 c0.053,-0.01,0.112,-0.019,0.144,-0.017 c0.028,0.001,0.093,-0.002,0.151,-0.023 c0.017,-0.006,0.038,-0.016,0.059,-0.041 c0.02,-0.024,0.034,-0.057,0.042,-0.091 c0.015,-0.063,-0.002,-0.101,0.015,-0.166 c0.01,-0.038,0.027,-0.061,0.035,-0.068 c0.025,-0.02,0.078,-0.005,0.091,-0.006 c0.028,-0.002,0.046,-0.011,0.06,-0.028 c0.008,-0.01,0.014,-0.021,0.019,-0.036 c0.01,-0.028,0.014,-0.058,0.018,-0.08 c0.011,-0.059,0.042,-0.08,0.039,-0.107 C0.989,0.011,0.653,0.024,0.385,0.021 C0.258,0.019,0.132,0.012,0.003,0"></path>
          </clipPath>
        </svg>
      </Container>
    </Background>
  );
};

export default Header;
