import * as React from "react";

function TopLeftSplash(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={2014.47}
      height={1408}
      {...props}
    >
      <path
        id="TopLeftSplash"
        d="M0 1408a690.793 690.793 0 0185.026-101.79c115.592-114 284.635-190.1 570.974-229.21 106.622-14.56 225.705-26.33 290-24 56.77 2.05 188.11-2.89 305-32 34.6-8.62 76.38-22.8 118-58 40.37-34.138 69.09-80.149 85-128 29.29-88.114-4.8-142.5 31-234 20.91-53.445 53.8-86.521 71-96 50.19-27.66 157.12-6.49 184-8 56.19-3.155 92.66-15.536 121-40 16.48-14.228 27.62-30.208 38-50 20.9-39.827 28.98-81.515 37-112 22.02-83.728 84.6-112.575 78-151C1991.81 14.867 1314.49 34.012 776 29 518.74 26.606 265.829 17.05 6 0z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default TopLeftSplash;
