import styled from "styled-components";

import ScrollingButton from "./ScrollingButton";
import Text from "../../../atoms/Text";
import Title from "../../../atoms/Title";
import Button from "../../../atoms/Button";
import Subtitle from "../../../atoms/Subtitle";
import Carousel from "./Carousel";

export const Container = styled.div`
height: 100%;
width: 100%;
z-index: 3;
position: relative;
background: var(--background-color));

`;

export const Wrapper = styled.div`
width: 100%;
height: 100%;
display: flex;

flex-direction: column;
padding-bottom: 200px;
padding-top: 100px;
justify-content: center;
align-items: center;
    background-image: -webkit-radial-gradient(
      50% -0px,
      circle closest-corner,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0) 50px,
      #00051C 51px,
      #00051C 52px
    );
    &:after {
      content: "";
      z-index: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: #122669;
      background: radial-gradient(circle at 50% 190%, #122669, #0f2565, #0a2159, #051c4a, #041639, #050f2a, #020820, #00051c);
      top: 50px;
    }
    &:before{
  content: "";
  width: 95px;
  height: 95px;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: -49px;
  left: 50%;
  transform: translateX(-50%);
}
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* top: 500px; */
  height: 200px;
  z-index: 100;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 120px;
`;
export {
  ScrollingButton,
  Text,
  Title,
  Button,
  Subtitle,
  Carousel,
  TextWrapper
};
