import React from "react";
import styled from "styled-components";
import Title from "../../../atoms/Title";
import Text from "../../../atoms/Text";
import Subtitle from "../../../atoms/Subtitle";
import Carousel from "react-multi-carousel";
const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;
const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1560px;
  display: flex;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 3.2vw;
`;
const LeftSideTextWrapper = styled.div``;
const RightSide = styled.div`
  width: 800px;
  background: lightgreen;
  height: 400px;
`;
const CarouselWrapper = styled.div`
  width: 630px;
  height: 500px;
`;
const ColaborationSection = () => {
  const className = "reccly-dev--goal-carousel";
  const goals = [
    {
      description: `Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vivamus suscipit tortor eget felis porttitor volutpat. Donec sollicitudin molestie malesuada. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Sed porttitor lectus nibh. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.`
    },
    {
      description: `Nulla porttitor accumsan tincidunt. Curabitur aliquet quam id dui posuere blandit. Sed porttitor lectus nibh. Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.`
    },
    {
      description: `Curabitur aliquet quam id dui posuere blandit. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec sollicitudin molestie malesuada. Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Sed porttitor lectus nibh. Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Cras ultricies ligula sed magna dictum porta.`
    },
    {
      description: `Donec sollicitudin molestie malesuada. Cras ultricies ligula sed magna dictum porta. Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Cras ultricies ligula sed magna dictum porta. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.`
    }
  ];
  return (
    <>
      <Container>
        <Wrapper>
          <LeftSide>
            <LeftSideTextWrapper>
              <Subtitle>Od zera do spełnienia</Subtitle>
              <Title>Etapy współpracy</Title>
            </LeftSideTextWrapper>
            <CarouselWrapper>
              <Carousel
                additionalTransfrom={0}
                autoPlaySpeed={3000}
                arrows={false}
                centerMode={false}
                showDots={true}
                className={className}
                containerClass={className + "--container"}
                dotListClass={className + "--dotlist"}
                itemClass={className + "--item"}
                draggable
                focusOnSelect={false}
                keyBoardControl
                minimumTouchDrag={80}
                responsive={{
                  always: {
                    breakpoint: {
                      max: 10000,
                      min: 0
                    },
                    items: 1
                  }
                }}
                ssr
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {goals.map((p, i) => (
                  <Text
                    key={className + "_key_" + i}
                    style={{
                      display: "block",
                      height: "100%",
                      margin: "auto",
                      width: "100%"
                    }}
                  >
                    {p.description}
                  </Text>
                ))}
              </Carousel>
            </CarouselWrapper>
          </LeftSide>
          <RightSide></RightSide>
        </Wrapper>
      </Container>
    </>
  );
};

export default ColaborationSection;
