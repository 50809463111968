import React from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { Link } from "gatsby";
import Button from "../../atoms/Button";
import { ButtonsGroup } from "./SliderButtons";
import { useCurrentSlide } from "../../organisms/home_page/PortfolioSection";

const Container = styled.div`
  width: 564px;
  height: 489px;
  position: relative;
  &:before {
    content: "";
    width: 564px;
    height: 489px;
    background: url(/portfolio_slides/komputerek.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 2;
    top: -160px;
    left: -35px;
  }
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: -20px;
  }
  .react-multiple-carousel__arrow {
    display: none;
  }
`;

const Monitor = styled.div`
  clip-path: polygon(0 0, 100% 3%, 100% 100%, 3% 100%);
  height: 550px;
  width: 493px;
`;
const Image = styled.div`
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 15px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 35px;
`;
const LinkWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;
  z-index: 100;
  transform: translateX(-80%);
`;
const LaptopSlider = ({ preview }) => {
  const carouselRef = React.useRef();
  const [bg, setBg] = React.useState();
  const [previewLink, setPreviewLink] = React.useState(preview[0].href);
  const [currentSlide, setCurrentSlide] = useCurrentSlide();
  React.useEffect(() => {
    console.log(carouselRef.current);
    // carouselRef.current.state.currentSlide = currentSlide;
    console.log(preview[carouselRef?.current?.state?.currentSlide]);
    // setPreviewLink(preview[carouselRef?.current?.state?.currentSlide - 1].href);
    setBg(
      <ButtonsGroup
        max={preview.length - 1}
        currentSlide={currentSlide}
        next={carouselRef?.current?.next}
        previous={carouselRef?.current?.previous}
      />
    );
  }, [carouselRef?.current?.state]);

  const className = "reccly-dev--portfolio-slider";
  return (
    <Container>
      <Monitor>
        <Image>
          <div
            style={{
              backgroundColor: "#333435",
              position: "relative"
            }}
          >
            <Carousel
              afterChange={(nextSlide, { currentSlide, onMove }) =>
                setCurrentSlide(currentSlide)
              }
              additionalTransfrom={0}
              autoPlaySpeed={3000}
              centerMode={false}
              className={className}
              containerClass={className + "--container"}
              dotListClass={className + "--dotlist"}
              itemClass={className + "--item"}
              draggable
              ref={carouselRef}
              focusOnSelect={false}
              keyBoardControl
              minimumTouchDrag={80}
              responsive={{
                always: {
                  breakpoint: {
                    max: 10000,
                    min: 0
                  },
                  items: 1
                }
              }}
              ssr
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {preview.map((p, i) => (
                <img
                  src={p.src}
                  key={p.src + "_" + i}
                  alt={p.src}
                  style={{
                    display: "block",
                    height: "100%",
                    margin: "auto",
                    width: "100%"
                  }}
                />
              ))}
            </Carousel>
          </div>
        </Image>
      </Monitor>
      <LinkWrapper>
        <a target="_blank" rel="noreferrer" href={preview[currentSlide].href}>
          <Button data_color="black">Zobacz na zywo</Button>
        </a>
      </LinkWrapper>
      {/* HERE's I RENDERING THE BUTTONS FOR STERING SLIDER, CREATED IN USEMEMO TO AVOID SSR ERROR */}
      {bg}
    </Container>
  );
};

export default LaptopSlider;
