import React, { useState, useContext } from "react";
import styled from "styled-components";
import Text from "../../../atoms/Text";
import Subtitle from "../../../atoms/Subtitle";
import Title from "../../../atoms/Title";
import LaptopSlider from "../../../molecules/LaptopSlider";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 180px;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 1560px;
  margin: 0 auto;
`;
const LaptopWrapper = styled.div`
  padding-left: 97px;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CurrentSlideContext = React.createContext();
export const SetCurrentSlideContext = React.createContext();

export const useCurrentSlide = () => [
  useContext(CurrentSlideContext),
  useContext(SetCurrentSlideContext)
];
const PorfolioSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const preview = [
    {
      src: "https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
      href: "https://webrand.pl",
      description:
        "Nulla porttitor accumsan tincidunt. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui."
    },
    {
      src: "https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      href: "https://shareyourfire.ch",
      description:
        "Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla porttitor accumsan tincidunt. Proin eget tortor risus."
    },
    {
      src: "https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
      href: "https://webrand.pl",
      description:
        "Nulla porttitor accumsan tincidunt. Donec rutrum congue leo eget malesuada. Proin eget tortor risus."
    },
    {
      src: "https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
      href: "https://webrand.pl",
      description:
        "Pellentesque in ipsum id orci porta dapibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus."
    }
  ];
  return (
    <CurrentSlideContext.Provider value={currentSlide}>
      <SetCurrentSlideContext.Provider value={setCurrentSlide}>
        <Container>
          <Wrapper>
            <LaptopWrapper>
              <LaptopSlider preview={preview} />
            </LaptopWrapper>
            <TextWrapper>
              <Subtitle>Co tworzyliśmy?</Subtitle>
              <Title>Nasze portfolio</Title>
              <Text>{preview[currentSlide].description}</Text>
            </TextWrapper>
          </Wrapper>
        </Container>
      </SetCurrentSlideContext.Provider>
    </CurrentSlideContext.Provider>
  );
};

export default PorfolioSection;
