import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { SetCurrentSlideContext } from ".";
import { useCurrentSlide } from "./index";

const buttonPulseAnimationBlue = keyframes`
0% {
  opacity: 0.3;
}
40% {
  opacity: 0.5;
  box-shadow: 0 0 0 5px var(--text-color), 0 0 10px 10px var(--text-color), 0 0 0 10px var(--text-color);
}
100% {
  box-shadow: 0 0 0 5px var(--text-color), 0 0 10px 10px var(--text-color), 0 0 0 10px var(--text-color);
  transform: scaleY(1.02);
  opacity: 0;
}
}`;
const buttonPulseAnimationBlueSecond = keyframes`
0% {
  opacity: 0.3;
}
40% {
  opacity: 0.5;
  box-shadow: 0 0 0 5px #04043d, 0 0 10px 10px #04043d, 0 0 0 10px #04043d;
}
100% {
  box-shadow: 0 0 0 5px #04043d, 0 0 10px 10px #04043d, 0 0 0 10px #04043d;
  transform: scaleY(1.02);
  opacity: 0;
}
}`;
const StyledCarouselButtonRight = styled.button`
  width: 38px;
  cursor: pointer;
  height: 38px;
  position: absolute;
  top: calc(50% - 20px);
  z-index: 500;
  left: calc(50% + 230px);
  background: white;
  transform: translate(-50% -50%);

  background: var(--addon-color);
  border: 0;
  border-radius: 50%;
  &:before {
    content: "";
    position: absolute;
    background: blue;
    background: url(/global/Arrow-right-2.svg);
    background-size: 100%;
    height: 13px;
    top: 50%;
    left: calc(50%);
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    background-repeat: no-repeat;
    filter: brightness(0) invert(1);
  }
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    top: 0;
    left: 0;
    animation: ${(props) =>
        props.data_clicked === true
          ? buttonPulseAnimationBlue
          : buttonPulseAnimationBlueSecond}
      2s forwards;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
    background: var(--background-color);
  }

  @media only screen and (min-width: 615px) {
    left: calc(50% + 176px);
    width: 70px;
    height: 70px;
    top: calc(50% + -175px);

    border-radius: 100%;

    &:before {
      height: 21px;
      border-radius: 100%;

      width: 23px;
      top: calc(50% - -2px);
    }
  }
`;

const StyledCarouselButtonLeft = styled.button`
  width: 38px;
  height: 38px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: calc(50% - 20px);
  left: calc(50% - 170px);
  transform: translate(-50% -50%);
  cursor: pointer;
  background: var(--addon-color);
  border-radius: 50%;
  border: 0;
  &:before {
    content: "";
    position: absolute;
    background: blue;
    background: url(/global/Arrow-right-2.svg);
    background-size: 100%;
    height: 13px;
    top: calc(50% - 13px);
    left: 50%;
    transform: rotate(180deg) translateX(50%) translateY(-50%);
    width: 15px;
    background-repeat: no-repeat;
    filter: brightness(0) invert(1);
    border-radius: 100%;
  }
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    top: 0;
    left: 0;
    animation: ${(props) =>
        props.data_clicked === true
          ? buttonPulseAnimationBlue
          : buttonPulseAnimationBlueSecond}
      2s forwards;
  }
  &:hover {
    transform: scale(1.05);
    background: var(--background-color);
  }
  @media only screen and (min-width: 615px) {
    left: calc(50% - 100px);
    width: 70px;
    height: 70px;
    top: calc(50% + -175px);

    &:before {
      height: 21px;
      width: 23px;
      top: calc(50% - 22px);
    }
  }
`;
export const RightArrow = ({ onClick, ...rest }) => {
  const [leftClicked, setLeftClicked] = useState(false);
  const handleOnClick = () => {
    onClick();
    setLeftClicked(!leftClicked);
  };
  // onMove means if dragging or swiping in progress.
  return (
    <StyledCarouselButtonRight
      data_clicked={leftClicked}
      onClick={() => handleOnClick()}
    ></StyledCarouselButtonRight>
  );
};

export const LeftArrow = ({ onClick, ...rest }) => {
  const [leftClicked, setLeftClicked] = useState(false);
  const handleOnClick = () => {
    onClick();
    setLeftClicked(!leftClicked);
  };
  // onMove means if dragging or swiping in progress.
  return (
    <StyledCarouselButtonLeft
      data_clicked={leftClicked}
      onClick={() => handleOnClick()}
    ></StyledCarouselButtonLeft>
  );
};
const Container = styled.div`
  /* position: absolute; */
  z-index: 2;
  top: 0;
`;
export const ButtonsGroup = ({ next, previous, max }) => {
  const [currentSlide, setCurrentSlide] = useCurrentSlide();

  const handleNext = () => {
    setCurrentSlide(currentSlide + 1);
    next();
  };
  const handlePrev = () => {
    setCurrentSlide(currentSlide - 1);
    previous();
  };
  return (
    <Container>
      <h1>{currentSlide}</h1>
      <h1>{max}</h1>

      {currentSlide !== 0 && <LeftArrow onClick={handlePrev} />}
      {currentSlide !== max && <RightArrow onClick={handleNext} />}
    </Container>
  );
};
