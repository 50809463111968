import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import Header from "../components/organisms/home_page/Header";
import PorfolioSection from "../components/organisms/home_page/PortfolioSection";
import WhoWeAreSection from "../components/organisms/home_page/WhoWeAreSection";
import ColaborationSection from "../components/organisms/home_page/ColaborationSection";
import OfferSection from "../components/organisms/home_page/OfferSection";
import PartnersSection from "../components/organisms/home_page/PartnersSection";

const Index = () => {
  return (
    <>
      <Layout>
        <Header />
        <PartnersSection />
        <OfferSection />
        <ColaborationSection />
        <PorfolioSection />
        <WhoWeAreSection />
      </Layout>
    </>
  );
};

export default Index;
