import React from "react";
import styled from "styled-components";
const Container = styled.div`
  z-index: 0;  
  width: 108vw;
  height: 68vw;
  top: -34vw;
  /* top: -700px; */
  left: 0px;
  position: relative;
  margin-bottom: -34vw;
  margin-top: 70px;
  margin-top: 34vw;
  background: url(/shape.svg);
  background-position-x: -5px;
  background-size: contain;
  
`;
const Shape = ({children}) => {
  return (
    <Container >
        {children}
        </Container >
  );
};

export default Shape;
