import React from "react";
import styled from "styled-components";
import Text from "../../../atoms/Text";
import Title from "../../../atoms/Title";
import Subtitle from "../../../atoms/Subtitle";
import Button from "../../../atoms/Button";
import Carousel from "react-multi-carousel";
import Shape from "./Shape";
const Container = styled.div`
  width: 100%;
  margin-top: -26vw;
  height: 100%;
  position: relative;
  z-index: 1;
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  height: 100%;
  display: flex;
`;
const LeftSide = styled.div`
  width: 50%;
  padding: 60px;
margin-top: 34vw;
`;
const RightSide = styled.div`
margin-top: 34vw;
  width: 50%;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const SectionWrapper = styled.div`
position: relative;
background: #fff;
width: 100%;
`
const CarouselContainer = styled.div``;
const WhoWeAreSection = () => {
  const className = "reccly-dev--whoweare-carousel";
  const howWeWorking = [
    {
      title: "Konsekwetne działania",
      description: `Działamy konsekwentnie, z każdego 
        staramy się wyciągać jak najwięcej wniosków.`,
      src: "/icons/facebook.svg"
    },
    {
      title: "Młody, dynamiczny zespół",
      description: `Do tego owocowe czwartki, karta multikulti i darmowy pendrive XD`,
      src: "/icons/facebook.svg"
    },
    {
      title: "Konsekwetne działania",
      description: `Działamy konsekwentnie, z każdego 
        staramy się wyciągać jak najwięcej wniosków.`,
      src: "/icons/facebook.svg"
    },
    {
      title: "Konsekwetne działania",
      description: `Działamy konsekwentnie, z każdego 
        staramy się wyciągać jak najwięcej wniosków.`,
      src: "/icons/facebook.svg"
    },
    {
      title: "Konsekwetne działania",
      description: `Działamy konsekwentnie, z każdego 
        staramy się wyciągać jak najwięcej wniosków.`,
      src: "/icons/facebook.svg"
    }
  ];
  return (
    <SectionWrapper>
      <Shape>
      <Container>
        <Wrapper>
          <LeftSide>
            <TextWrapper>
              <Subtitle color="white">Kim jesteśmy?</Subtitle>
              <Title color="white">Po prostu RECCLY!</Title>
              <Text color="white">
                RECCLY to też agencja kreatywna, stworzona po to aby rwalizować
                wyjątkowe projekty. Poza tworzeniem stron internetowych, bardzo
                dobrze stawiamy swoją pozycję w branży filmowej oraz
                fotograficznej.
              </Text>
              <Button data_color="blue">Dołącz do nas!</Button>
            </TextWrapper>
          </LeftSide>
          <RightSide>
            <CarouselContainer>
              <Carousel
                additionalTransfrom={0}
                autoPlaySpeed={3000}
                arrows={false}
                centerMode={false}
                showDots={true}
                className={className}
                containerClass={className + "--container"}
                dotListClass={className + "--dotlist"}
                itemClass={className + "--item"}
                draggable
                focusOnSelect={false}
                keyBoardControl
                minimumTouchDrag={80}
                responsive={{
                  always: {
                    breakpoint: {
                      max: 10000,
                      min: 0
                    },
                    items: 1
                  }
                }}
                ssr
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {howWeWorking.map((p, i) => (
                  <div
                    key={className + "_key_" + i}
                    style={{
                      display: "block",
                      height: "100%",
                      margin: "auto",
                      width: "100%"
                    }}
                  >
                    <img
                      src={p.src}
                      alt={p.title}
                      style={{
                        display: "block",
                        height: "50px",
                        margin: "auto",
                        width: "50px"
                      }}
                    />
                    <Title color="white">{p.title}</Title>
                    <Text color="white">{p.description}</Text>
                  </div>
                ))}
              </Carousel>
            </CarouselContainer>
          </RightSide>
        </Wrapper>
      </Container>
      </Shape>

    </SectionWrapper>
  );
};

export default WhoWeAreSection;
