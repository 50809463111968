import React from "react";
import styled, { keyframes } from "styled-components";
const buttonPulseAnimationBlue = keyframes`
0% {
  opacity: 0.3;
}
40% {
  opacity: 0.5;
  box-shadow: 0 0 0 5px #31A1FF, 0 0 10px 10px #31A1FF, 0 0 0 10px #31A1FF;
}
100% {
  box-shadow: 0 0 0 5px #31A1FF, 0 0 10px 10px #31A1FF, 0 0 0 10px #31A1FF;
  transform: scaleY(1.05);
  opacity: 0;
}
}`;
const ScrolldownButton = styled.button`
  cursor: pointer;
  position: absolute;
  width: 48px;
  height: 48px;
  z-index: 23;
  left: calc(50% - 0.5px);
  top: -25.5px;
  border: 0px;
  box-shadow: 0px 0px 21px -2px #00000045;
  border-radius: 100%;
  transform: translateX(-50%);
  background: var(--background-color-dark);
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: scale(1.24) translateX(-40%);
  }
  &:after {
    position: absolute;
    content: "";
    background: url(/global/Arrow-right-2.svg);
    filter: brightness(100);
    transform: translate(-50%, -50%) rotate(90deg);
    background-size: 14px;
    width: 14px;
    left: calc(50% - 1px);
    height: 14px;
    background-repeat: no-repeat;
    top: calc(50% + 1px);
  }
  &:before {
    content: "";
    border-radius: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 48px;
    height: 48px;
    animation: ${buttonPulseAnimationBlue} 2s linear infinite;
  }
  @media only screen and (min-width: 615px) {
    width: 60px;
    top: -30px;
    height: 60px;
    &:before {
      content: "";
      border-radius: 100%;
      left: 0;
      top: 0;
      position: absolute;
      width: 60px;
      height: 60px;
      animation: ${buttonPulseAnimationBlue} 2s linear infinite;
    }
    &:after {
      width: 24px;
      left: calc(50% - 2px);
      height: 24px;
      background-size: 22px;
      background-repeat: no-repeat;
      top: calc(50% + 1px);
    }
  }
  @media only screen and (min-width: 1024px) {
    width: 60px;
    height: 60px;
    top: -30px;
    &:after {
      position: absolute;
      content: "";
      background: url(/global/Arrow-right-2.svg);
      transform: translate(-50%, -50%) rotate(90deg);
      background-size: 22px;
      width: 24px;
      left: calc(50% - 2px);
      height: 24px;
      background-repeat: no-repeat;
      top: calc(50% + 1px);
    }
  }
`;
const ScrollingButton = (props) => {
  return <ScrolldownButton {...props} />;
};

export default ScrollingButton;
