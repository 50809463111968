import React, { useState, useEffect } from "react";
import useMediaType from "../../../hooks/useMediaType";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/core";
import Text from "../../../atoms/Text";
import Title from "../../../atoms/Title";
import "swiper/css";

SwiperCore.use([Autoplay]);

const StyledWrapper = styled.div`
  max-width: 1000px;
  position: relative;
  width: 90vw;
  height: 90px;
  z-index: 2;
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  justify-content: center;
  user-select: none;
  align-items: center;
  z-index: 0;
  .reccly-dev--partners-swiper {
    /* box-shadow: 0px 5px 17px -7px rgba(0, 0, 0, 0.09); */
    .swiper-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    border-radius: 100px;
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &:after {
    content: "";
    width: 100vw;
    margin-top: 50px;
    z-index: 0;
    height: 100%;
    background: #fff;
    position: absolute;
  }
  @media only screen and (min-width: 428px) {
    bottom: 125px;
  }
  @media only screen and (min-width: 517px) {
    bottom: 150px;
  }
  @media only screen and (min-width: 615px) {
    bottom: 95px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 25px;
  }
  @media only screen and (min-width: 1024px) {
    bottom: 125px;
  }
`;
const TextWrapper = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

const PartnersSection = () => {
  const mediaType = useMediaType();
  const [media, setMedia] = useState(mediaType);
  useEffect(() => {
    setMedia(mediaType);
  }, [mediaType]);

  const SwiperLogos = [
    <SwiperSlide style={{ width: "140px", height: "50px" }}>
      <img
        style={{ width: "140px", height: "50px" }}
        src="/home_page/sponsors/gbsdobrzejest.svg"
      />
    </SwiperSlide>,
    <SwiperSlide style={{ width: "300px", height: "100px" }}>
      <img
        style={{ width: "300px", height: "30px" }}
        src="/home_page/sponsors/masno.svg"
      />
    </SwiperSlide>,
    <SwiperSlide style={{ width: "100px", height: "50px" }}>
      <img
        style={{ width: "100px", height: "50px" }}
        src="/home_page/sponsors/mommies.svg"
      />
    </SwiperSlide>
  ];
  const ShowLogosForSlider = (swiperLogos) => {
    const mediaType = useMediaType();
    const [media, setMedia] = useState(mediaType);
    useEffect(() => {
      setMedia(mediaType);
    }, [mediaType]);
    let result = [];
    for (let i = 0; i < 3; i++) swiperLogos.map((logo) => result.push(logo));
    return result;
  };

  return (
    <StyledContainer>
      <TextWrapper>
        <Title>Nasi klienci</Title>
        <Text>Zaufanie to fundament dobrej współpracy.</Text>
      </TextWrapper>
      <StyledWrapper>
        <Swiper
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={media === "mobile" ? 40 : 90}
          mousewheel={true}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          className="reccly-dev--partners-swiper"
        >
          {ShowLogosForSlider(SwiperLogos)}
        </Swiper>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default PartnersSection;
