import React, { useState, useContext } from "react";
import TopShape from "./TopShape";

import {
  ScrollingButton,
  Title,
  Subtitle,
  Container,
  Wrapper,
  Carousel,
  TextWrapper
} from "./Components";

export const CurrentSlideContext = React.createContext();
export const SetCurrentSlideContext = React.createContext();

export const useCurrentSlide = () => [
  useContext(CurrentSlideContext),
  useContext(SetCurrentSlideContext)
];

const OfferSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const preview = [
    {},
    {},
    {
      src: "/offer/laptop.png",
      title: "Optymalizacja SEO",
      type: "optymalizacji SEO",

      description:
        "Nulla porttitor accumsan tincidunt. Donec rutrum congue leo eget."
    },
    {
      src: "/offer/laptop.png",
      title: "Aplikacje mobilne",
      description:
        "Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla.",
      type: "aplikacjii mobilnej"
    },
    {
      src: "/offer/laptop.png",
      title: "Strony WWW",
      description:
        "Nulla porttitor accumsan tincidunt. Vestibulum ac diam sit amet quam.",
      type: "strony internetowej"
    },
    {
      src: "/offer/laptop.png",
      title: "Systemy CRM/ERP",
      type: "autorskiego systemu CRM/ERP",
      description: "Pellentesque in ipsum id orci porta dapibus. Lorem ipsum."
    },
    {
      src: "/offer/laptop.png",
      type: "sklepu internetowego",
      title: "Sklepy internetowe",
      description:
        "Pe dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus."
    },
    {},
    {}
  ];
  const scrollToRef = React.useRef();
  return (
    <CurrentSlideContext.Provider value={currentSlide}>
      <SetCurrentSlideContext.Provider value={setCurrentSlide}>
        <Container ref={scrollToRef}>
          <ScrollingButton
            onClick={() =>
              window.scrollTo(0, scrollToRef.current.offsetTop - 70)
            }
          />
          <Wrapper>
            <TextWrapper>
              <Subtitle color="white">Czym się specjalizujemy?</Subtitle>
              <Title style={{ marginTop: "-20px" }} color="white">
                Poznaj naszą ofertę!
              </Title>
            </TextWrapper>
            <Carousel preview={preview} />
          </Wrapper>
          <TopShape />
        </Container>
      </SetCurrentSlideContext.Provider>
    </CurrentSlideContext.Provider>
  );
};

export default OfferSection;
